import { useEffect, useState, useRef } from "react";

import gsap from "gsap";
import styled from "styled-components";

import { CONSTANTS } from "../../constants";

const HomeSectionWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${CONSTANTS.primaryColor};
  padding-top: ${CONSTANTS.headerHeight};
`;

const WelcomeTitle = styled.h1`
  margin: 0;
  font-size: 5rem;
  font-weight: 200;

  @media (max-width: 480px) {
    font-size: 3rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  @media (max-width: 722px) {
    flex-direction: column;
  }
`;
const HTMLTag = styled.h1`
  margin: 0;
  font-size: 5rem;
  font-weight: 200;
  transform: scaleX(0);
`;

const AboutInfo = styled.div`
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40%;
  text-align: center;
  @media (max-width: 722px) {
    max-width: 80%;
    margin: 2rem 0;
  }
`;

const AboutText = styled.p`
  margin: 0.5rem 0;
`;

const AboutTitle = styled.h1`
  font-weight: 200;
  font-size: 2.5rem;
  margin: 0;
`;

const textTitle = "Welcome!";
export const Home = () => {
  const titleRef = useRef(null as any);
  const contentRef = useRef(null as any);
  const htmlTag1Ref = useRef(null as any);
  const htmlTag2Ref = useRef(null as any);

  useEffect(() => {
    const animation = gsap.timeline();
    animation
      .delay(1)
      .fromTo(titleRef.current, {
				y: "-4rem",
			} ,{
        opacity: 0,
        y: -150,
        duration: 1,
        display: "none",
      })
      .fromTo(
        contentRef.current,
        {
          y: 100,
          display: "none",
          opacity: 0,
        },
        {
          display: "flex",
          opacity: 1,
          y: "-4rem",
          duration: 1,
        }
      )
      .to([htmlTag1Ref.current, htmlTag2Ref.current], {
        transform: "scaleX(1)",
        duration: 0.5,
      });
  }, []);

  return (
    <HomeSectionWrapper>
      <WelcomeTitle ref={titleRef}>{textTitle}</WelcomeTitle>

      <FlexWrapper ref={contentRef}>
        <HTMLTag ref={htmlTag1Ref}>{"<>"}</HTMLTag>

        <AboutInfo>
          <AboutTitle>PavolOlbert.com</AboutTitle>
          <AboutText>
            Hello ! My name is Pavol and I{"'"}m FrontEnd Developer from Bratislava, Slovakia.
            Currently, my main focus is on ReactJS and I have been working on developing my skills
            also on the BackEnd side through NodeJS.
          </AboutText>
        </AboutInfo>
        <HTMLTag ref={htmlTag2Ref}>{"</>"}</HTMLTag>
      </FlexWrapper>
    </HomeSectionWrapper>
  );
};
