import { useEffect, useRef } from "react";

import gsap from "gsap";
import styled from "styled-components"

import { CONSTANTS } from "../../constants";
import { scrollToElement } from "../../utils/scrollToElement";

const MobileNavBackDrop = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100vh - ${CONSTANTS.headerHeight});
  background: rgba(0, 0, 0, 0.5);
  top: ${CONSTANTS.headerHeight};
  @media (min-width: 768px) {
    display: none;
  }
`;

const NavItemsWrapper = styled.div`
  border-radius: 0 0 10px 10px;
  overflow: hidden;
`;

const NavItem = styled.div<{ darkBackground: boolean }>`
  height: 70px;
  background: white;
  color: ${CONSTANTS.primaryColor};
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  & :hover {
    text-decoration: underline;
  }
`;

type Props = {
  opened: boolean;
  onClick: () => void;
  scrollBackground: boolean;
};
export const MobileNav = (props: Props) => {
  const refs = {
    linkRef1: useRef(null as any),
    linkRef2: useRef(null as any),
    linkRef3: useRef(null as any),
  } as const;
  useEffect(() => {
    const animation = gsap.timeline();
    animation.fromTo(
      [refs.linkRef1.current, refs.linkRef2.current, refs.linkRef3.current],
      {
        left: "-100%",
      },
      { left: 0, stagger: 0.1, duration: 0.4 }
    );
  }, []);

  return (
    <MobileNavBackDrop onClick={() => props.onClick()}>
      <NavItemsWrapper>
        {CONSTANTS.navLinks.map((navLink, index) => (
          <NavItem
            //@ts-expect-error
            ref={refs[`linkRef${index + 1}` as string]}
            key={navLink.title}
            darkBackground={props.scrollBackground}
            onClick={(e: any) => {
              e.stopPropagation();
              props.onClick();
              scrollToElement(navLink.elementId);
            }}
          >
            {navLink.title}
          </NavItem>
        ))}
      </NavItemsWrapper>
    </MobileNavBackDrop>
  );
};
