import CSS from "./stackimages/css.png";
import HTML from "./stackimages/html.png";
import Javascript from "./stackimages/javascript.png";
import Next from "./stackimages/nextjs.png";
import NodeJS from "./stackimages/nodejs.png";
import Postgres from "./stackimages/postgres.png";
import React from "./stackimages/react.png";
import Typescript from "./stackimages/typescript.png";

export const StackImages = {
  CSS,
  HTML,
  Next,
  NodeJS,
  Postgres,
  React,
  Typescript,
  Javascript,
};
