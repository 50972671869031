import { useEffect, useState } from "react";

import styled from "styled-components";

import { CONSTANTS } from "../../constants";
import { scrollToElement } from "../../utils/scrollToElement";

import { HamburgerIcon } from "./HamburgerIcon";
import { MobileNav } from "./MobileNav";

type WrapperProps = {
  scrollBackground: boolean;
  navOpened: boolean;
};
const HeaderWrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  min-height: ${CONSTANTS.headerHeight};
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    props.scrollBackground && !props.navOpened ? "white" : CONSTANTS.primaryColor};
  color: ${(props) =>
    props.scrollBackground && !props.navOpened ? CONSTANTS.primaryColor : "white"};
  box-shadow: ${(props) =>
    props.scrollBackground
      ? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
      : ""};

  width: 100%;
  font-size: 1.8rem;
  position: fixed;
  z-index: 2000;
  @media (max-width: 768px) {
    padding-right: 1rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ItemWrapper = styled.div`
  margin: 0 1rem;
  z-index: 200;
  font-weight: 200;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2rem;
  cursor: pointer;
  & > div {
    opacity: 0;
    transform: scaleX(0);
    transition: all 0.2s ease-out;
    transform-origin: 0% 100%;
  }

  &:hover {
    & > div {
      opacity: 1;
      transform: scaleX(1);
    }
  }
`;

const Underline = styled.div<{ scrollBackground: boolean }>`
  height: 0.1rem;
  background: ${(props) => (props.scrollBackground ? CONSTANTS.primaryColor : "white")};
`;

type Props = {
  title: string;
  elementId: string;
  scrollBackground: boolean;
};

const HeaderItem = (props: Props) => {
  return (
    <ItemWrapper onClick={() => scrollToElement(props.elementId)}>
      {props.title}
      <Underline scrollBackground={props.scrollBackground} />
    </ItemWrapper>
  );
};
export const Header = () => {
  const [scrollBackground, setScrollBackground] = useState(false);
  const [navOpened, setNavOpened] = useState(false);

  const callBack = () => {
    if (window.scrollY > 100) {
      setScrollBackground(true);
    } else {
      setScrollBackground(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", callBack);

    return () => window.removeEventListener("scroll", callBack);
  }, []);
  return (
    <HeaderWrapper scrollBackground={scrollBackground} navOpened={navOpened}>
      <ItemWrapper
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setNavOpened(false);
        }}
      >
        PavolOlbert
      </ItemWrapper>
      <NavLinks>
        {CONSTANTS.navLinks.map((navLink) => {
          return (
            <HeaderItem
              scrollBackground={scrollBackground}
              key={navLink.title}
              elementId={navLink.elementId}
              title={navLink.title}
            />
          );
        })}
      </NavLinks>
      <HamburgerIcon
        scrollBackground={scrollBackground}
        opened={navOpened}
        onClick={() => setNavOpened((p) => !p)}
      />
      {navOpened && (
        <MobileNav
          scrollBackground={scrollBackground}
          opened={navOpened}
          onClick={() => setNavOpened((p) => !p)}
        />
      )}
    </HeaderWrapper>
  );
};
