import { useEffect, useRef, useState } from "react";

import gsap from "gsap";
import styled from "styled-components";

import { CONSTANTS } from "../../../constants";

const TechStackSectionWrapper = styled.div`
  display: flex;
  margin-top: 4rem;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

const SectionTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 4rem;
  letter-spacing: 2px;
  text-align: center;
`;

const ProjectWrapper = styled.div`
  opacity: 0;
  min-height: 200px;
  background: white;
  color: ${CONSTANTS.primaryColor};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  width: 50%;
  padding: 0 1rem;
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
    padding-bottom: 1rem;
  }

  @media (max-width: 1120px) {
    width: 60%;
  }
  @media (max-width: 700px) {
    width: 70%;
  }
  @media (max-width: 650px) {
    width: 90%;
  }
`;

const ProjectDuration = styled.div`
  min-height: 100%;
  margin: 0 1rem;
  width: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  @media (max-width: 550px) {
    margin: 0;
    justify-content: center;
    height: 40px;
    width: 100%;
  }
`;

const ProjectInfo = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  border-left: 2px solid ${CONSTANTS.primaryColor};
  @media (max-width: 550px) {
    padding: 0;
    border-left: 0px;
    padding-top: 0.3rem;
    border-top: 2px solid ${CONSTANTS.primaryColor};
  }
`;

const ProjectTitle = styled.h3`
  margin: 0;
	& > a {
		text-decoration/

	}
`;

const ProjectEmployer = styled.p`
  color: grey;
  margin: 0;
  line-height: 1;
`;
const ProjectTechStack = styled.p<{ margin?: boolean }>`
  margin: 0;
  margin-top: ${(props) => (props.margin ? "1rem" : "")};
  & > span {
    color: grey;
  }
`;

const ProjectWeb = styled.p`
  margin-top: 1rem;
  margin-bottom: 0;
  line-height: 1;

  & > a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
  & > span {
    color: grey;
  }
`;

const ProjectDescription = styled.p`
  margin-top: 1rem;
`;
export const Projects = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const projectsWrapperRef = useRef(null as any);
  const projectsWrapper2Ref = useRef(null as any);
  useEffect(() => {
    const callBack = () => {
      if (window.scrollY > document.getElementById("projects")?.offsetTop! - 300) {
        setIsAnimated(true);
        window.removeEventListener("scroll", callBack);
      }
    };

    window.addEventListener("scroll", callBack);

    return () => window.removeEventListener("scroll", callBack);
  }, []);

  useEffect(() => {
    if (!isAnimated) return;
    gsap
      .timeline()
      .fromTo(
        projectsWrapperRef.current,
        {
          x: 100,
        },
        {
          opacity: 1,
          duration: 0.4,
          x: 0,
        }
      )
      .fromTo(
        projectsWrapper2Ref.current,
        {
          x: -100,
        },
        {
          opacity: 1,
          duration: 0.4,
          x: 0,
        }
      );
  }, [isAnimated]);
  return (
    <TechStackSectionWrapper id="projects">
      <SectionTitle>Projects</SectionTitle>
      <ProjectWrapper ref={projectsWrapperRef}>
        <ProjectDuration>Sep 2021 - Jan 2022</ProjectDuration>

        <ProjectInfo>
          <div>
            <ProjectTitle>Investment Web Application</ProjectTitle>
            <ProjectEmployer>ARTIN, spol. s.r.o.</ProjectEmployer>
            <ProjectDescription>
              A web application created in NextJS framework. The whole application was written in
              Typescript. The communication with backend was provided via GraphQL with usage of
              Apollo including API layer Backend for Frontend.
            </ProjectDescription>
          </div>
          <ProjectTechStack margin>
            <span>Tech Stack: </span>
            NextJS, TypeScript, HTML, CSS, GraphQL, GIT
          </ProjectTechStack>
        </ProjectInfo>
      </ProjectWrapper>
      <ProjectWrapper ref={projectsWrapper2Ref}>
        <ProjectDuration>Jun 2021 - Aug 2021</ProjectDuration>
        <ProjectInfo>
          <div>
            <ProjectTitle>Trainee program IT Absolvent</ProjectTitle>
            <ProjectEmployer>Smartbrains solutions s.r.o.</ProjectEmployer>
            <ProjectDescription>
              An innovative trainee program with focus on ReactJS and Typescript. Through the whole
              course we developed many small aplications such as TicTacToe or ToDoList under the
              supervision of the senior developer.
            </ProjectDescription>
          </div>
          <div>
            <ProjectWeb>
              <span>Web: </span>
              <a href="https://www.it-absolvent.cz/" target="_blank" rel="noreferrer">
                www.it-absolvent.cz
              </a>
            </ProjectWeb>
            <ProjectTechStack>
              <span>Tech Stack: </span>
              ReactJS, TypeScript, HTML, CSS, REST API, GIT
            </ProjectTechStack>
          </div>
        </ProjectInfo>
      </ProjectWrapper>
    </TechStackSectionWrapper>
  );
};
