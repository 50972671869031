import { useEffect, useRef, useState } from "react";

import gsap from "gsap";
import styled from "styled-components";

import { CONSTANTS } from "../../../constants";

import { StackImages } from "./images";

const TechStackSectionWrapper = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SectionTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 4rem;
  letter-spacing: 2px;
  text-align: center;
`;

const SkillTabsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  opacity: 0;
  justify-content: center;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SkillTab = styled.div`
  background: white;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  width: 30%;
  padding: 0 1rem;
  padding-bottom: 1rem;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  @media (max-width: 1120px) {
    width: 40%;
  }
  @media (max-width: 700px) {
    width: 46%;
  }
  @media (max-width: 650px) {
    width: 70%;
  }
  @media (max-width: 470px) {
    width: 90%;
  }
`;

const SkillHeader = styled.div`
  border-bottom: 0.5px solid ${CONSTANTS.primaryColor};
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
`;

const SkillTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${CONSTANTS.primaryColor};
`;

const SkillSubtitle = styled.p`
  color: ${CONSTANTS.primaryColor};
  margin: 0;
  margin-bottom: 1rem;
`;

const ImageWrapper = styled.div`
  height: 96px;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SkillHoverWrapper = styled.div`
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const SkillWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
`;

const ImgStyled = styled.img`
	 height: 96px;
  width: 96px;
`

const SkillName = styled.p`
  color: ${CONSTANTS.primaryColor};
`;
type Props = {
  title: string;
  subtitle?: string;
  skills: any[];
};
const SkillComponent = (props: Props) => {
  return (
    <SkillTab>
      <SkillHeader>
        <SkillTitle>{props.title}</SkillTitle>
      </SkillHeader>
      {props.subtitle && <SkillSubtitle>{props.subtitle}</SkillSubtitle>}
      <SkillWrapper>
        {props.skills.map((skill, index) => (
          <SkillHoverWrapper key={index}>
            <ImageWrapper>
              <ImgStyled src={skill.image} alt="css" />
            </ImageWrapper>
            <SkillName>{skill.name}</SkillName>
          </SkillHoverWrapper>
        ))}
      </SkillWrapper>
    </SkillTab>
  );
};

const SKILLSETS = [
  {
    title: "Front End",
    skills: [
      { name: "HTML", image: StackImages.HTML },
      { name: "CSS", image: StackImages.CSS },
      { name: "Javascript", image: StackImages.Javascript },
      { name: "Typescript", image: StackImages.Typescript },
      { name: "ReactJS", image: StackImages.React },
      { name: "NextJS", image: StackImages.Next },
    ],
  },
  {
    title: "Back End",
    subtitle: "Exploring and learning...",
    skills: [
      { name: "NodeJS", image: StackImages.NodeJS },
      { name: "PostgreSQL", image: StackImages.Postgres },
    ],
  },
];
export const TechStack = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const tabsWrapperRef = useRef(null as any);
  useEffect(() => {
    const callBack = () => {
      if (window.scrollY > document.getElementById("techstack")?.offsetTop! - 300) {
        setIsAnimated(true);
        window.removeEventListener("scroll", callBack);
      }
    };

    window.addEventListener("scroll", callBack);

    return () => window.removeEventListener("scroll", callBack);
  }, []);

  useEffect(() => {
    if (!isAnimated) return;
    gsap.fromTo(
      tabsWrapperRef.current,
      {
        x: -100,
      },
      {
        opacity: 1,
        duration: 0.4,
        x: 0,
      }
    );
  }, [isAnimated]);

  return (
    <TechStackSectionWrapper id="techstack">
      <SectionTitle>Tech Stack</SectionTitle>
      <SkillTabsWrapper ref={tabsWrapperRef}>
        {SKILLSETS.map((skillSet) => (
          <SkillComponent
            key={skillSet.title}
            title={skillSet.title}
            skills={skillSet.skills}
            subtitle={skillSet.subtitle ?? ""}
          />
        ))}
      </SkillTabsWrapper>
    </TechStackSectionWrapper>
  );
};
