import styled from "styled-components";

import { CONSTANTS } from "../../../constants";

import Email from "./images/email.png";
import LinkedIn from "./images/linkedin.png";
import Phone from "./images/phone.png";

const ContactSectionWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: white;
`;

const SectionTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 4rem;
  letter-spacing: 2px;
  text-align: center;
`;

const ContactWrapper = styled.div`
  min-height: 200px;
  color: ${CONSTANTS.primaryColor};
  overflow: hidden;
`;

const ContactTitle = styled.h1`
  color: white;
  font-weight: 300;
  margin: 2rem 0;
`;

const ContactsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 1rem;
  gap: 30px;
`;

const OneContact = styled.div<{ email?: boolean }>`
  height: 200px;
  min-width: 200px;
  background: white;
  padding: 0 1rem;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  @media (max-width: 771px) {
    ${(props) => (props.email ? "order: 3" : "")}
  }

  @media (max-width: 283px) {
    max-width: 250px;
  }
`;

const ContactDetail = styled.a`
  text-decoration: none;
  color: ${CONSTANTS.primaryColor};
	  transition: transform 0.2s ease-out;
`;
export const Contact = () => {
  return (
    <ContactSectionWrapper id="contact">
      <SectionTitle>Contact</SectionTitle>
      <ContactWrapper>
        <ContactsWrapper>
          <OneContact>
            <img src={LinkedIn} alt="linkedin" />
            <ContactDetail href="https://www.linkedin.com/in/pavol-olbert/" target="_blank">
              Pavol Olbert
            </ContactDetail>
          </OneContact>
          <OneContact email>
            <img src={Email} alt="email" />
            <ContactDetail
              href="mailto:pavol.olbert@pavololbert.com
.com"
            >
              pavol.olbert@pavololbert.com
            </ContactDetail>
          </OneContact>
          <OneContact>
            <img src={Phone} alt="phone" />
            <ContactDetail href="tel:+421948722883">+421 948 722 883</ContactDetail>
          </OneContact>
        </ContactsWrapper>
      </ContactWrapper>
      <ContactTitle>Feel free to contact me!</ContactTitle>
    </ContactSectionWrapper>
  );
};
