import styled from "styled-components";

import { CONSTANTS } from "../../constants";

type BarStickProps = {
  darkColor: boolean;
  order: number;
  opened: boolean;
};
const BarStick = styled.div<BarStickProps>`
  width: 35px;
  height: 5px;
  background-color: ${(props) =>
    props.darkColor && !props.opened ? CONSTANTS.primaryColor : "white"};
  margin: 6px 0;
  transition: 0.4s;
  opacity: ${(props) => (props.order === 2 && props.opened ? 0 : 1)};
  transform: ${(props) =>
    props.order === 1 && props.opened
      ? "rotate(-45deg) translate(-9px, 6px)"
      : props.order === 3 && props.opened
      ? "rotate(45deg) translate(-8px, -8px)"
      : "none"};
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

type Props = {
  scrollBackground: boolean;
  opened: boolean;
  onClick: () => void;
};

export const HamburgerIcon = (props: Props) => {
  return (
    <Wrapper onClick={() => props.onClick()}>
      <BarStick opened={props.opened} order={1} darkColor={props.scrollBackground} />
      <BarStick opened={props.opened} order={2} darkColor={props.scrollBackground} />
      <BarStick opened={props.opened} order={3} darkColor={props.scrollBackground} />
    </Wrapper>
  );
};
