import styled from "styled-components";

const FooterWrapper = styled.div`
  padding: 1rem 0;
  border-top: 1px solid white;
  text-align: center;
  color: white;
`;
export const Footer = () => {
  return <FooterWrapper>© 2021-2022 Pavol Olbert</FooterWrapper>;
};
