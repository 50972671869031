
import React from 'react';
import { Header } from "./layout/header/Header";
import { Footer } from "./layout/footer/Footer";
import { Contact } from "./Index/sections/Contact.tsx/Contact";
import { Home } from "./Index/sections/Home";
import { Projects } from "./Index/sections/projects/Projects";
import { TechStack } from "./Index/sections/techstack/TechStack";
import { createGlobalStyle } from "styled-components";
import {CONSTANTS} from "./constants"
//@ts-expect-error
import smoothscroll from "smoothscroll-polyfill"


smoothscroll.polyfill();

const GlobalStyle = createGlobalStyle`
	body {
		font-family: 'Poppins', sans-serif;
		margin: 0;
		background: ${CONSTANTS.primaryColor};
	}
   body * {
		 box-sizing: border-box;
		 margin: 0

   }
 `;


function App() {
  return (
    <div>
			  <GlobalStyle />
      <Header />
 		<Home />
      <TechStack />
      <Projects />
      <Contact />
			<Footer/>
    </div>
  );
}

export default App;
