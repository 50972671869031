export const CONSTANTS = {
  headerHeight: "4rem",
  primaryColor: "#0b172a",
  navLinks: [
    {
      title: "Tech Stack",
      type: "idLink",
      elementId: "techstack",
    },
    {
      title: "Projects",
      type: "idLink",
      elementId: "projects",
    },
    {
      title: "Contact",
      type: "idLink",
      elementId: "contact",
    },
  ],
};
